//
// Footer
//

#section-footer {
//  background: $color_background_grey;
  h1,
  h2 {
    color: $color_text_dark;
  }
  img {
    height: auto;
  }
}

#block-block-2,
#block-block-8 {
    @include breakpoint($breakpoint_desktop) {
    @include span(18 of 18);
    }
}

#block-webform-client-block-4 {
//  margin-top: $margin;
  padding-bottom: $padding*2;
    @include breakpoint($breakpoint_tablet) {
    @include span(9 of 18);
    }
  h2 {
    margin-bottom: $margin;
    margin-top: $margin;
  }
}


#block-webform-client-block-4,
#block-block-6 {
  padding-top: $padding;
}

#region-footer-first {
//  background-image: $bg_contact;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 0 10px;
  padding-top: $padding;
}

#region-footer-second {
//  background: $color_background_body;
//  background-image:  $bg_pattern;
//  background-repeat: repeat;
//  background-position: 0 0;
  background-image:  $bg_footer;
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: 54%;
  color: $color_text_dark;
  float: left;
  clear: both;
  width: 100%;
  @include breakpoint($breakpoint_tablet) {
  margin: 0 -50% !important;
  padding: 0 50%;
  padding-bottom: 3em;
  width: 200%;
  }
}

#block-block-2 {
  padding-top: 9em !important;
  background-image:  $bg_promysleno;
  background-repeat: no-repeat;
  background-position: top left;
  background-size: 25%; 
}


#block-block-8 {
  background: $color_background_body;
    
  color: $color_text_dark;
  margin: 0 !important;
  padding: 0 $padding;
  float: left;
  clear: both;
  width: 100%;
  @include breakpoint($breakpoint_tablet) {
  margin: 0 -50% !important;
  padding: 0 50%;
  width: 200%;
  }
}