//
// Body
//

html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}

body {
  font-family: $font;
  color: $color_text;
  text-align: center;
  background-image:  $bg_body;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  overflow-x: hidden;
  @include breakpoint($breakpoint_tablet) {

  }
  @include breakpoint($breakpoint_desktop) {

  }
}
#zone-branding-wrapper {
//    background: $color_background_body;
//    background-image:  $bg_pattern;
//    background-repeat: repeat;
//    background-position: 0 0;
    color: $color_text_light;
//    background-image:  $bg_header;
    #region-branding {
        padding-bottom: $padding/4;
        //  border-bottom: $border;
        padding-right: gutter();
        padding-left: gutter(); 
    }
}

#zone-menu-wrapper {
//    background: $color_background_body;
//    background-image:  $bg_pattern;
//    background-repeat: repeat;
//    background-position: 0 0;
    color: $color_text_dark;
//    background-image:  $bg_header;
    background-repeat: repeat;
    background-position: 0 0;
    overflow-x: hidden;
    #region-menu {
        //  border-bottom: $border;
        padding-right: gutter();
        padding-left: gutter();
    }
    h2 {
        color: $color_text_light;
    }
}

#region-content {
//    background: $color_background_light;
    padding-right: gutter();
    padding-left: gutter();
}

#region-footer-second {  
    .region-inner {
    padding-right: gutter();  
    padding-left: gutter();
    margin: 8px;
    }
}

#sliding-popup.sliding-popup-top {
  clear: both;
  float: left;
}

