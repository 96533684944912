//
// Header
//

#section-header {
//  margin-bottom: $margin*3;

}

#zone-branding-wrapper {
      @include breakpoint($breakpoint_tablet) {
//    background: $color_background_light;
    }
}

body.front div.branding-data {
    width: 100%;
    text-align: center;
    background-image:  $bg_header;
    background-repeat: no-repeat;
    background-position: top right;
    background-size: 90%;
}

.logo-img {
    margin: em(45px);
    float: none;
}

.logo-img img {
    max-width: em(450px) !important;
    height: auto;
}

.site-name {
  margin-top: 9px;
}

.site-slogan {
  margin: em(18px) em(18px) em(9px) em(9px);
}


h1.site-name,
h2.site-name {
  font-size: responsive 16px 22px; /* min-size, max-size */
  font-range: 420px 1440px; /* min-range, max-range */
  a {
    color: $color_text_dark;
    text-decoration: none !important;
    border-bottom: solid 3px $color_menu_border;
  }
  
}

h6.site-slogan {
  font-weight: normal !important;
  font-family: $font;
}

.branding-data.clearfix {
  
    @include breakpoint($breakpoint_tablet) {
    float: left;
    }
}