//
// Nodes
//

// HomePage

#block-views-bannery-block {
//    padding-top: $padding;
}


body.front .content {
    margin-bottom: $margin/2;
    margin-top: $margin/2;
    p {
    width: span(18);  
    }
    div.cv {
        padding-right: $padding;
        width: span(18);
        @include breakpoint($breakpoint_tablet) {
            float: left;
            width: span(9);
        }

    }
    div.cv.last {
        @include breakpoint($breakpoint_tablet) {
        padding-right: 0px !important;
        padding-left: $padding;
        }
    }
    h2.h2tym {
        margin-top: $margin*2;
        float: left;
    }
}

div.owl-item .views-field-title {
  position: absolute;
  bottom: 18px;
  width: 100%;
  text-align: center;
  background: $color_background_banner_title;

    h2 a {
      color: $color_text_dark;
    }
}

div.view-bannery h2 {
  text-align: center; 
}


#block-views-bannery-block {
  margin-bottom: $margin*2;
}

// Aktualita
body.node-type-article #region-content {
  padding-bottom: $padding;
}