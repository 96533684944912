//
// In Node gallery
//
div.field-name-field-foto {
  margin-bottom: $margin*2;
  float: left;
  width: 100%; 

  div.field-item {
    img {
    width: 100%;
    }
    &:hover {
      opacity: 0.5;
    }
    @include gallery(9 of 18, of-type);
    margin-bottom: gutter();
    
    @include breakpoint($breakpoint_tablet) {
    @include gallery(18/4 of 18, of-type);
    margin-bottom: gutter();
    }
    
    @include breakpoint($breakpoint_desktop) {
    @include gallery(18/4 of 18, of-type);
    margin-bottom: gutter();
    }
  }
}