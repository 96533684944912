//
// Blocks
//

#block-search-form {
    @include breakpoint($breakpoint_tablet) {
    position: absolute;
    right: 18px;
    top: 9px;
    z-index: 10;
  }
}

#block-system-main {
  @include span(18);

}


// HP Sluzby Teasers

body.front {
    div.view-slu-by .views-row {
        padding-right: $padding;
        @include span(18);
        @include breakpoint($breakpoint_tablet) {
            @include gallery(18/3 of 18, of-type);
        }
    }
    @include breakpoint($breakpoint_tablet) {
        p.sluzbateaser {
            min-height: 100px;
        }
    }
}



// Sluzby

body.not-front {
    div.view-slu-by .views-row {
        margin-top: $margin*2;
    }
}

#my-nid-48,
#my-nid-49,
#my-nid-50 {
    margin-bottom: $margin*2;
    span.vice {
        padding: 9px;
        background: $color_background_footer;
        color: $color_text_light;
        a {
            color: $color_text_light;
            }
        }
}

#my-nid-48 div.views-field-field-foto a,
#my-nid-49 div.views-field-field-foto a,
#my-nid-50 div.views-field-field-foto a {
    margin-top: gutter();
    margin-bottom: $margin*3;
    @include span(18);
    @include breakpoint($breakpoint_tablet) {
        @include gallery(18/3 of 18, of-type);
    }
}

body.page-sluzby div.content {
    #my-nid-48 div.views-field-title,
    #my-nid-48 div.views-field-body,
    #my-nid-48 div.views-field-field-foto,
    #my-nid-49 div.views-field-title,
    #my-nid-49 div.views-field-body,
    #my-nid-49 div.views-field-field-foto,
    #my-nid-50 div.views-field-title,
    #my-nid-50 div.views-field-body,
    #my-nid-50 div.views-field-field-foto {
        @include span(18);
        img {
        border: $border;
        // -webkit-box-shadow: 1px 1px 5px 1px #696969;
        // box-shadow: 1px 1px 5px 1px #696969;
        }
    }
}
// Fotogalerie

div.content {
    div.view-fotogalerie-1 .views-row,
    div.view-fotogalerie-2 .views-row,
    div.view-fotogalerie-3 .views-row {
        margin-top: gutter();
        margin-bottom: $margin*5;
        @include span(18);
        @include breakpoint($breakpoint_tablet) {
            @include gallery(18/3 of 18, of-type);
        }
        img {
            border: $border;
            // -webkit-box-shadow: 1px 1px 5px 1px #696969;
            // box-shadow: 1px 1px 5px 1px #696969;
        }
        .views-field-title {
            margin-left: $margin*3;
            margin-top: -$margin*5;
        }
    }
}


// News

#block-views-blok-novinek-block {
  @include span(18);
  border-bottom: solid 3px $color_menu_border;
  padding: $padding*2 0 $padding*3 0;
  .block-inner > h2 {
    padding-bottom: $padding;
    color: $color_menu_border;
  }
  .views-field-title h2 {
    margin: 0; 
  }
  
  .views-field-nothing {
    text-align: right;
  }
}

#block-views-blok-novinek-block-1 {
  margin: $margin 0px;
  padding: $padding 0px;
  border-bottom: $border;
  border-top: $border;
  .views-field-nothing {
    text-align: right;
  }
  h2.block-title {
    margin-bottom: $margin;
  }
}


div.view-blok-novinek .views-row {
    margin-bottom: gutter();
    @include span(18);
    @include breakpoint($breakpoint_tablet) {
    @include gallery(18/4 of 18, of-type);
    }
    img {
      width: span(18);
      margin-right: gutter();
      @include breakpoint($breakpoint_tablet) {
        width: span(18);
        margin-right: gutter(6);
      }
    }
}

body.node-type-article article img {
  float: left;
  margin-right: gutter();
}

