//
// Menu
//

#block-superfish-1 {
//  text-transform: uppercase;
    margin-top: $margin*2;
    float: right;
  #superfish-1 {
    margin-bottom: $margin/2;
  }
  @include breakpoint($breakpoint_tablet) {
    
  }
  li {
    background: transparent;
    color: $color_text_light;
    border: none;
    font-size: responsive 16px 21px; /* min-size, max-size */
    font-range: 420px 1440px; /* min-range, max-range */
    margin: em(0px) em(1px) em(0px) em(27px);
//      border-right: $border;
  }
  li.last {
    border: none;
  }
  a {
    background: transparent;
    color: $color_text_light;
    border: none;
    font-size: responsive 12px 16px; /* min-size, max-size */
    font-range: 420px 1440px; /* min-range, max-range */
    margin: em(0px) em(1px);
    padding: em(3px) em(12px);
  }
  a:hover,
  a.active {
    text-decoration: underline;
  }
}
