//
// Views
//

// Sluzby

p.anchor {
    visibility: hidden;
}

// Fotogalerie

div.views-field-field-fotografie,
div.views-field-field-foto {
    @include span(18);
    div.polaroid_thumb {
        margin-top: gutter();
        margin-bottom: $margin*5;
        @include span(18);
        @include breakpoint($breakpoint_tablet) {
            @include gallery(18/3 of 18, of-type);
        }
        img {
            border: $border;
            // -webkit-box-shadow: 1px 1px 5px 1px #696969;
            // box-shadow: 1px 1px 5px 1px #696969;
        }
        .polaroid_title {
            margin-left: $margin*3;
            margin-top: -$margin*5;
            display: block;
        }
    }
    div.views-field-title {
        @include span(18);
    }
}
