//
// Colors
//
$color_background_body: rgba(#ffffff, 1);
$color_background_grey_body: rgba(#cccccc, 1);
$color_background_light: rgba(#ffffff, 0.9);
$color_background_banner_title: rgba(#ffffff, 0.72);
$color_background_grey: rgba(#e5e5e5, 0.5);
$color_background_black: rgba(#000000, 1);
$color_background_footer: rgba(#252525, 1);
$color_background_karton: rgba(#caa078, 1);
$color_text: rgba(#333333, 1);
$color_text_light: rgba(#ffffff, 1);
$color_text_dark: rgba(#000000, 1);
$color_menu_text: rgba(#000000, 1);
$color_h: rgba(#000000,1);
$color_a: rgba(#ffcc00,1);
$color_border: rgba(#cccccc,1);
$color_menu_border: rgba(#4abbec,1);

