//
// Typo
//

@font-face {
  font-family: 'montserratregular';
  src: url('../fonts/MontserratRegular/Montserrat-Regular.eot');
  src: url('../fonts/MontserratRegular/Montserrat-Regular.eot?#iefix') format('embedded-opentype'),
       url('../fonts/MontserratRegular/Montserrat-Regular.woff') format('woff'),
       url('../fonts/MontserratRegular/Montserrat-Regular.ttf') format('truetype'),
       url('../fonts/MontserratRegular/Montserrat-Regular.svg#montserratregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'montserratbold';
  src: url('../fonts/MontserratBold/Montserrat-Bold.eot');
  src: url('../fonts/MontserratBold/Montserrat-Bold.eot?#iefix') format('embedded-opentype'),
       url('../fonts/MontserratBold/Montserrat-Bold.woff') format('woff'),
       url('../fonts/MontserratBold/Montserrat-Bold.ttf') format('truetype'),
       url('../fonts/MontserratBold/Montserrat-Bold.svg#montserratbold') format('svg');
  font-weight: normal;
  font-style: normal;
}

$font: 'montserratregular', 'open-sans', arial;
$hfont: 'montserratbold', 'open-sans', arial;

p, pre, ul, span  {
  font-size: responsive 11px 16px; /* min-size, max-size */
  font-range: 420px 1440px; /* min-range, max-range */
}

h1 {
  font-size: responsive 21px 36px; /* min-size, max-size */
  font-range: 420px 1440px; /* min-range, max-range */
}

h2,
h2.block-title {
  font-size: responsive 21px 36px; /* min-size, max-size */
  font-range: 420px 1440px; /* min-range, max-range */
}