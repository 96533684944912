//
// HTML Elements
//

html {
  font-size: responsive;
  line-height: responsive;
}

p, dl, hr, h1, h2, h3, h4, h5, h6, ol, ul, pre, table, address, fieldset {
  margin-bottom: $margin/2;
}

a,
a:link,
a:visited,
a:active,
a.active {
  text-decoration: none !important;
  color: $color_a;
}

a.active,
a:hover,
input.form-submit:hover {
  text-decoration: underline !important;
}


p, pre {
  padding: $padding/2 0;
}

img {
  max-width: 100%;
  height: auto !important;
}

a > img:hover {
      opacity: 0.72;
}

div.owl-carousel,
div.field-name-field-fotografie-v-z-hlav- {
  img {
    width: 100%;
    height: auto;
  } 
}

strong {
  font-weight: normal !important;
  font-family: $hfont;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $hfont;
  margin-bottom: $margin/2;
  margin-top: $margin/2;
  letter-spacing: 0.05em;
  color: $color_h;
}

h1.site-name a,
h2.site-name a
{
  text-decoration: none;
}

.site-name,
.site-slogan {
}

h1 {
  @include breakpoint($breakpoint_tablet) {
  }
  @include breakpoint($breakpoint_desktop) {
  }
}
  
h2 {
  @include breakpoint($breakpoint_tablet) {
  }
  @include breakpoint($breakpoint_desktop) {
  }
}

body.front #page-title {
//  display: none;
}

#section-content ol,
#section-content ul
{
  padding-left: $padding/2;
}

#section-content ul li
{
  list-style: none;
}

#block-system-main ul li:before,
#block-block-7 ul li:before {
  color: $color_h;
  content:"\2022"; /* /2022 is the numerical unicode for &bull; */
  font-size:1.5em; /* set the font-size of the bullet if necessary. Make sure to use em or %*/
  padding-right:.25em; /* give the bullet some padding from the text Make sure to use em*/
  position:relative;
  top:.1em; /* set the vertical position of the bullet if necessary. Make sure to use em or % */
}

button,
input,
select,
textarea,
input[type="reset"],
input[type="submit"],
input[type="button"] {
  border: 1px solid #b3b3b0;
  border-radius: 0px 0px 0px 0px !important;
  max-width: 100%;
}

button:focus,
button:active,
input:focus,
input:active,
select:focus,
select:active,
textarea:focus,
textarea:active {
  box-shadow: 0 0 7px #696969;
}